import { Product } from '@interface/product';

export default class ProductService {
  static async getAllProducts({
    getAgendaProducts = false,
  }: {
    getAgendaProducts?: boolean;
  }): Promise<Product[]> {
    const urlApi = process.env.NEXT_PUBLIC_PRODUCTS_API;

    try {
      const url = getAgendaProducts
        ? `${urlApi}Product?getAgendaProducts=true`
        : `${urlApi}Product`;

      const cacheBehavior = 'no-store';
      const res = await fetch(url, { cache: cacheBehavior });
      if (res.ok) {
        const data = await res.json();
        return data;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }
  static async getDashboardProducts(
    clinicId: string,
    getUpgrades = false
  ): Promise<Product[]> {
    try {
      let url = `${process.env.NEXT_PUBLIC_PRODUCTS_API}DashboardProducts?clinicId=${clinicId}`;
      url = getUpgrades ? url + '&getUpgrades=true' : url;
      const res = await fetch(url);
      if (res.ok) {
        const data = await res.json();
        const products = data
          .map((product: Product) => ({
            ...product,
            visibility: true,
          }))
          .sort((a: any, b: any) => (a.price > b.price ? 1 : -1));

        return products;
      } else {
        return [];
      }
    } catch (err) {
      return [];
    }
  }

  static async getProduct(
    slug: string,
    isDashboard: boolean
  ): Promise<Product | undefined> {
    const apiUrl = process.env.NEXT_PUBLIC_PRODUCTS_API;

    try {
      const url = `${apiUrl}Product/${slug}?Dashboard=${isDashboard.toString()}`;

      console.log(url);

      const cacheBehavior =
        process.env.NODE_ENV === 'production' ? 'force-cache' : 'no-store';

      const res = await fetch(url, { cache: cacheBehavior });

      if (res.ok) {
        const data = await res.json();
        return data;
      } else {
        return undefined;
      }
    } catch (err) {
      return undefined;
    }
  }
}
